import {
  PUBLIC_SENTRY_DSN,
  PUBLIC_SENTRY_ENVIRONMENT,
} from "$env/static/public";
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,
  environment: PUBLIC_SENTRY_ENVIRONMENT,
  tracesSampleRate: 0.01,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
    Sentry.replayCanvasIntegration(),
  ],
  //replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.1,
  //debug: !import.meta.env.PROD,
  enabled: import.meta.env.PROD,
  beforeSend(event, hint) {
    // Check if the error message explicitly mentions a CSS element not found
    // TypeError: error loading dynamically imported module: https://www.bolighub.dk/_app/immutable/nodes/51.D96TnP3i.css
    // TypeError: error loading dynamically imported module: https://www.bolighub.dk/_app/immutable/chunks/PDPMap.DkAClMjz.js
    if (hint.originalException && hint.originalException.message) {
      // Define a specific and more targeted error message pattern
      const errorMessage = hint.originalException.message.toLowerCase();
      if (errorMessage.includes("Unable to preload CSS")) {
        return null; // Don't send the event to Sentry
      }
    }
    // // Check if the error type is TypeError and the message matches a specific pattern
    if (
      hint.originalException instanceof TypeError &&
      hint.originalException.message.includes(".at is not a function")
    ) {
      // You can modify the event to add more context or tag it specifically
      event.tags = { ...event.tags, errorType: "AtFunctionError" };
      event.extra = {
        ...event.extra,
        message: hint.originalException.message,
        // Add more diagnostics as needed
      };

      // Log a custom message or handle the error in a specific way
      console.error(
        "An outdated browser may be causing a JavaScript error:",
        hint.originalException.message,
      );

      // You can still return the event if you want it sent to Sentry
      return event;
    }
    return event; // Send all other events
  },
});

import type { HandleClientError } from "@sveltejs/kit";

const myErrorHandler: HandleClientError = ({ error, event }) => {
  console.error("An error occurred on the client side:", error);
};

export const handleError = Sentry.handleErrorWithSentry(
  myErrorHandler,
) satisfies HandleClientError;
