import type { ParamMatcher } from "@sveltejs/kit";

export const match = ((param) => {
  const property_types = [
    // Catch all
    "properties-to-rent",
    // Predefined accommodation types
    "apartment",
    "house",
    "room",
    "townhouse",
    "student-friendly-accommodation",
    "student-housing",
    "senior-friendly-accommodation",
    "co-living",
    "roommate",
    "roomie",
  ];
  return property_types.includes(param);
}) satisfies ParamMatcher;
