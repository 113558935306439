import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88')
];

export const server_loads = [0,26,29];

export const dictionary = {
		"/": [37],
		"/(landing-pages)/FAQ": [~46,[6]],
		"/(landing-pages)/about-bolighub-old": [49,[8]],
		"/(landing-pages)/about-bolighub": [~48],
		"/admin": [~67,[23]],
		"/admin/customers/stripe": [~68,[23]],
		"/admin/mobilepay_tests": [~69,[23]],
		"/admin/users/set_user_inactive": [~70,[23]],
		"/api/fb_events": [~71],
		"/api/mobilepay": [~72],
		"/(authflow)/auth/new-user": [~38,[2]],
		"/(authflow)/auth/signin": [39],
		"/(authflow)/auth/verify-request": [40,[3]],
		"/(landing-pages)/blog": [~50,[9]],
		"/(landing-pages)/blog/[slug]": [~51,[9]],
		"/(terms-and-conditions)/cookie-policy": [~63,[19]],
		"/(supersearcher)/deactivate-searchagent/[user_id]/[id]": [~60,[18]],
		"/(landing-pages)/fair-rental": [52,[10]],
		"/(authflow)/forgot-password": [41,[4]],
		"/(landing-pages)/housing-benefits": [53,[11]],
		"/landlord/[company_name]": [73,[24]],
		"/(landing-pages)/new-landlord": [~54,[12]],
		"/partner/energidrift": [74,[25]],
		"/(landing-pages)/pendler": [55,[13]],
		"/(terms-and-conditions)/private-policy": [~64,[20]],
		"/(pdp)/property-to-rent/[title_slug]": [~59,[17]],
		"/(landing-pages)/rental-estimate": [56,[14]],
		"/(authflow)/reset-password": [~42,[5]],
		"/(supersearcher)/search-agent": [~61],
		"/(authflow)/signin": [43],
		"/(authflow)/signout": [44],
		"/(authflow)/signup": [45],
		"/(supersearcher)/supersearcher": [~62],
		"/(landing-pages)/support": [~57,[15]],
		"/(landing-pages)/tenant": [~58,[16]],
		"/(terms-and-conditions)/terms": [~65,[21]],
		"/user": [75,[26]],
		"/user/create-listing/[id]": [~76,[26]],
		"/user/create-search-agent": [77,[26,27]],
		"/user/edit-listing/[id]": [~78,[26]],
		"/user/favorite-listings": [79,[26,28]],
		"/user/inbox": [80,[26,29]],
		"/user/inbox/[listing_id]": [81,[26,29,30]],
		"/user/inbox/[listing_id]/[conversation_id]": [82,[26,29,30,31]],
		"/user/listings": [83,[26,32]],
		"/user/payment": [84,[26]],
		"/user/pdp/[id]": [85,[26,33]],
		"/user/profile": [86,[26,34]],
		"/user/profile/settings": [87,[26,34,35]],
		"/user/search-agents": [88,[26,36]],
		"/(landing-pages)/[studentCampaign=validStudentCampaign]": [47,[7]],
		"/[property_type=validPropertyType]/[...area]": [~66,[22]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';